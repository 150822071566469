import jQuery from 'jquery';

jQuery(function(){
    jQuery('body').on('click', '.js-nav-toggle', function(evt) {
	evt.preventDefault();

	const $el = jQuery(evt.currentTarget);
	const $nav = $el.siblings('.global-nav');

	if ($el.hasClass('is-open')) {
	    $el.removeClass('is-open');
	    $nav.removeClass('is-visible');
	} else {
	    $el.addClass('is-open');
	    $nav.addClass('is-visible');
	}
    });
});
