window.Library = window.Library || {};

module.exports = {
    addLibrary: function(libraryObject, libraryPath) {
	var current = window.Library;
	for(var idx = 0; idx < libraryPath.length - 1; idx++) {
	    var key = libraryPath[idx];
	    current[key] = current[key] || {};
	    current = current[key];
	}

	var key = libraryPath[libraryPath.length -1];
	current[key] = libraryObject;
    }
}
