import Library from 'library';
import Mousetrap from 'mousetrap';
import MicroModal from 'micromodal';


class Search {
    constructor(el) {
	this.el = el;

	this.el.addEventListener("keyup", (evt) => {this.keyupHandler(evt)});
    }

    keyupHandler(evt) {
	if(evt.isComposing) {
	    return true;
	}

	if(evt.key === "Enter") {
	    evt.preventDefault();

	    let searchValue = this.el.value;
	    let isNum = /^\d+$/.test(searchValue);
	    if(isNum) {
		// Appears to be a buildId, jump to that build
		window.location = '/builds/detail/' + searchValue;
	    }
	}
    }

    focus() {
	this.el.focus();
    }
}

let init = function () {
    MicroModal.init();
    let search = new Search(document.getElementById('shortcuts-search'));   

    Mousetrap.bind('g s', () => { 
	MicroModal.show('search-modal');
	search.focus();
	return false;
    });
};

Library.addLibrary(init, ['frontend', 'search', 'init']);
